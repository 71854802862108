.Footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  right: 0;
  height: 75px;
  border-top: 1px solid #919191;
  padding: 0 8px;
  background-color: #fff;
}

.Footer__orders {
  display: inline-block;
  width: 250px;
  border-radius: 15px;
  line-height: 60px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  background-color: #4bb34b;
  color: #fff;
}
