@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.Home {
  margin: 0;
  padding: 0;
}

.Home__fixed-content {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: #fff;
}

.Home__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 67px;
  padding: 0 12px;
  background-color: #fff;

}

.Home__head {
  overflow: hidden;
  margin: 0;
  color: #000;
}

.Home__logo {
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.Home__change-tz {
  width: 45px;
  height: 45px;
}

.Home__tabs {
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin: 0;
  border-bottom: 1px solid #919191;
  padding: 0;
}

.Home__tab {
  flex-basis: 33%;
  position: relative;
  overflow: hidden;
  padding: 0 10px;
  white-space: nowrap;
  list-style: none;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 50px;
  font-size: 19px;
}

.Home__tab_active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #3f8ae0;
}

.Home__tab_disabled {
  color: #717171;
  cursor: text;
}

.Home__edit {}

.Home__items {
  margin: 0;
  padding: 115px 0 75px;
}

.Home__item {
  list-style: none;
}

.Home__food-link {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  box-sizing: border-box;
  height: 120px;
  border-bottom: 1px solid #a3a3a3;
  padding: 0 10px;
  text-decoration: none;
}

.Home__food-link:hover,
.Home__food-link:active {
  background-color: #e6e6e6;
}

.Home__food-name {
  margin: 25px 0 4px;
  font-size: 22px;
  font-weight: 400;
  color: #000;
}

.Home__food-type {
  width: calc(100% - 140px);
  margin: 0;
  font-size: 16px;
  color: #545454;
}

.Home__image {
  width: 120px;
  height: 120px;
}

.Place {}

.Place__header {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
}

.Place__trz {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 67px;
  padding: 0 12px;
  background-color: #fff;
}

.Place__head {
  overflow: hidden;
  margin: 0;
  color: #000;
}

.Place__logo {
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  font-size: 24px;
  font-weight: 600;
  font-weight: normal;
  color: #000;
}

.Place__change-tz {
  width: 45px;
  height: 45px;
}

.Place__restoraunt {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 120px;
  margin-top: 67px;
  border-bottom: 1px solid rgba(161, 161, 161, .3);
  background-color: #e6e6e6;
}

.Place__restoraunt-name {
  margin: 25px 0 0;
  font-size: 28px;
  font-weight: 400;
  color: #000;
}

.Place__restoraunt-type {
  width: calc(100% - 140px);
  margin: 0;
  font-size: 16px;
  color: #545454;
}

.Place__foods {
  margin: 0 0 75px;
  padding: 0;
}

.Place__food {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  height: 130px;
  border-bottom: 1px solid #a2a2a2;
  padding: 12px 10px;
  list-style: none;
}

.Place__food-logo-wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 90px;
  height: 100%;
}

.Place__food-logo {
  width: 90px;
  height: 90px;
}

.Place__food-name {
  width: calc(100% - 120px);
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  color: #000;
}

.Place__food-composition {
  width: calc(100% - 120px);
  margin: 0;
  font-size: 16px;
  color: #545454;
}

.Place__food-price {
  display: flex;
  align-items: center;
  width: calc(100% - 120px);
  margin-top: 8px;
  white-space: nowrap;
  line-height: 40px;
  font-size: 20px;
}

.Place__restoraunt-logo {
  width: 120px;
  height: 120px;
}

.Place__food-price-price {
  margin-right: auto;
}

.Place__food-button {
  box-sizing: border-box;
  width: 34px;
  height: 34px;
  border: 3px solid #000;
  border-radius: 3px;
  font-size: 20px;
  font-weight: 700;
}

.Place__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 75px;
  border-top: 1px solid #a3a3a3;
  padding: 10px 12px;
  background-color: #fff;
}

.Place__order {
  flex-grow: 1;
  height: 65px;
  border: 0;
  border-radius: 25px;
  line-height: 60px;
  text-align: center;
  text-decoration: none;
  font-size: 22px;
  background-color: #4bb34b;
  color: #fff;
}

.Place__products-wrapper {
  position: relative;
}

.Place__products {
  margin: 0;
  border-bottom: 1px solid #a3a3a3;
  padding: 10px 30px 30px;
}

.Place__product {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 65px;
}

.Place__product-logo {
  width: 50px;
  height: 50px;
  padding-top: 8px;
}

.Place__product-name {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.Place__product-price {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #868686;
}

.Place__product-count {
  margin: 0 0 0 auto;
  padding: 0;
  line-height: 65px;
  font-size: 24px;
}

.Place__change-product {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  right: 10px;
  bottom: -22px;
  width: 120px;
  height: 45px;
  border-radius: 22px;
  padding: 0 10px;
  text-align: center;
  text-overflow: ellipsis;
  line-height: 45px;
  text-decoration: none;
  font-size: 20px;
  background-color: #4986cc;
  color: #fff;
}

.Place__choice h3 {
  display: inline-block;
  margin: 0;
  padding: 0 30px 0 0;
  font-size: 19px;
  font-weight: 400;
  color: #000;
}

/* .Place__choice-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
} */

.Place__choice div {
  vertical-align: middle;
  margin-bottom: 10px;
}

.Place__choice span {
  padding-right: 10px;
  font-size: 24px;
  color: #868686;
}

.Place__choice input {
  outline: 0;
  width: 120px;
  height: 36px;
  margin-left: 20px;
  border-width: 0 0 1px 0;
  border-color: #000;
  font-size: 24px;
}

.Place__choice {
  padding: 40px 20px 96px;
}

.Place__order-body {
  flex-grow: 1;
  text-align: center;
}

.Place__order-number-header {
  margin: 10px 0 0;
  padding: 0;
  font-size: 20px;
  font-weight: 400;
}

.Place__order-number {
  font-size: 30vh;
}

.Place_order {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Place__order-time {
  font-size: 5vh;
}

.Place_order .Place__header {
  position: static;
}

.Place_order .Place__restoraunt {
  margin-top: 0;
}

.Place_order .Place__footer {
  position: static;
}

.Checkbox {
  display: inline-block;
  position: relative;
  width: 38px;
  height: 38px;
  border: 1px solid #000;
}

.Checkbox input[type="checkbox"] {
  position: relative;
  z-index: 1;
  opacity: 0;
  width: 38px;
  height: 38px;
  margin: 0;
  padding: 0;
}

.Checkbox input[type="checkbox"]:checked ~ img {
  opacity: 1;
}

.Checkbox img {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 38px;
  height: 38px;
}

.Orders {}

.Orders__active-orders {
  margin: 0;
  padding: 10px;
}

.Orders__active-order {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 40px;
  border-radius: 20px;
  padding: 10px 0;
  font-size: 16px;
  background-color: #ebedf0;
  color: #000;
}

.Orders__left {
  padding-left: 20px;
}

.Orders__header {
  margin: 0;
  padding: 0;
  font-weight: 300;
}

.Orders__shop-name {
  margin: 0;
  padding: 0;
}

.Orders__price {
  margin: 0;
  padding: 0;
}

.Orders__time {
  padding-right: 10px;
  line-height: 60px;
  font-size: 24px;
  color: #4bb34b;
}

.Orders__finished-orders {
  margin: 0;
  padding: 10px;
}

.Orders__repeat {
  margin-right: 10px;
  border: 0;
  background: 0;
}

.Orders__refresh {
  width: 55px;
  height: 55px;
}

.Orders__dark {
  color: #383838;
}

.Orders__pink {
  color: #818c99;
}

.Orders__finished-order {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 10px 0;
  font-size: 16px;
  background-color: #c3c3c3;
  color: #f7b7ce;
}

.Orders__change {
  position: absolute;
  right: 125px;
  bottom: -30px;
  width: 100px;
  height: 30px;
  border-bottom-left-radius: 20px;
  text-align: center;
  text-decoration: none;
  line-height: 30px;
  font-size: 20px;
  background-color: #72c66b;
  color: #fff;
  cursor: pointer;
}

.Orders__cancel {
  position: absolute;
  right: 25px;
  bottom: -30px;
  width: 100px;
  height: 30px;
  border: 0;
  border-bottom-right-radius: 20px;
  text-align: center;
  text-decoration: none;
  line-height: 30px;
  font-size: 20px;
  background-color: #c72c2c;
  color: #fff;
  cursor: pointer;
}

.Footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  right: 0;
  height: 75px;
  border-top: 1px solid #919191;
  padding: 0 8px;
  background-color: #fff;
}

.Footer__orders {
  display: inline-block;
  width: 250px;
  border-radius: 15px;
  line-height: 60px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  background-color: #4bb34b;
  color: #fff;
}

